import * as React from 'react'
import { string } from 'prop-types'
import classNames from 'classnames'
import Circle from '@/utils/Circle'
import LazyLoadIcon from '@/utils/LazyLoadIcon'

function Avatar({ image, className, ...rest }) {
	return (
		<Circle
			className={classNames(
				'bg-primary-5 text-primary w-xl h-xl overflow-hidden',
				{ 'w-xl h-xl': !className },
				className
			)}
			{...rest}
		>
			{image ?
			(
				<LazyLoadIcon
					dir="clients"
					icon={image}
					remote
					className='ie:w-full ie:h-full'
					wrapperClassName="w-full h-full"
					alt="User avatar"
				/>
			) : (
					<LazyLoadIcon
						dir="clients"
						icon={AVATAR}
						className='ie:w-full ie:h-full'
						wrapperClassName="w-full h-full"
					/>
				)}
		</Circle>
	)
}

Avatar.propTypes = {
	image: string,
	className: string
}

export default Avatar
