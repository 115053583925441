/* eslint-disable compat/compat */
import * as React from 'react'
import { string } from 'prop-types'
import { useAsync } from 'react-async-hook'
import domify from 'domify'
import classNames from 'classnames'
import Node from '@/utils/Node'

const getSVGNode = domObject =>
{
	for(let i = 0; i < domObject.childNodes.length; i++)
		if(domObject.childNodes[i].nodeName === "svg")
			return domObject.childNodes[i]

		return null;
}


const fetchSVG = async url => (await fetch(url)).text()
const toNum = num => parseFloat(num, 10)

function DynamicRemoteIcon({ icon, className, wrapperClassName, as = 'span' }) {
	const fetchDetails = useAsync(fetchSVG, [icon])
	const ref = React.useRef()
	const status = React.useRef()

	const ratio = React.useMemo(() => {
		if (fetchDetails.status === 'success' && !status.current) {
			status.current = true

			const fetchResultDOM = domify(fetchDetails.result, document)
			const svg = fetchResultDOM.nodeName === "svg" ? fetchResultDOM : getSVGNode(fetchResultDOM)

			if (svg && svg.nodeName === 'svg') {
				const viewBox = svg?.viewBox?.baseVal
				// if there is no viewBox, presume it's a square
				const [w, h] = viewBox?.width ? [viewBox.width, viewBox.height] : [1, 1]

				ref.current.appendChild(svg)

				svg.classList.add(
					...classNames(className, 'absolute inset-0 w-full h-full m-auto', {
						'fill-current': !className
					}).split(' ')
				)

				return `${(toNum(h) / toNum(w)) * 100}% `
			}

			return null
		}
	}, [fetchDetails.status, fetchDetails.result, className])

	return (
		<Node className={classNames(wrapperClassName, 'block')} as={as}>
			<span
				ref={ref}
				className="relative block w-full h-0"
				style={{ paddingTop: ratio }}
			/>
		</Node>
	)
}

DynamicRemoteIcon.propTypes = {
	className: string,
	wrapperClassName: string,
	as: string,
	icon: string.isRequired
}

export default DynamicRemoteIcon
