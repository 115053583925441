import * as React from 'react'
import { node } from 'prop-types'
import Text from '@/utils/Text'
import Center from '@/utils/Center'
import Spinner from '@/utils/Spinner'

function Loading({ children }) {
	return (
		<Center className="flex flex-col w-full h-screen" data-testid="loading">
			<Spinner className="mb-xl" style={{ width: 80, height: 80 }} />
			<Text as="h2" className="text-xl text-center font-h-light">
				{children}
			</Text>
		</Center>
	)
}

Loading.propTypes = {
	children: node.isRequired
}

export default Loading
