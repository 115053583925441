import * as React from 'react'
import { func, bool, string } from 'prop-types'
import classNames from 'classnames'
import MenuIcon from '@/icons/icon-menu-mobile.svg'
import CloseIcon from '@/icons/icon-not-avail-x.svg'
import Center from '@/utils/Center'
import IconWrapper from '@/utils/IconWrapper'
import styles from './BurgerButton.module.css'

function BurgerButton({ onClick, isOpen, className }) {
	const Icon = isOpen ? CloseIcon : MenuIcon

	return (
		<Center
			as="button"
			className={classNames(styles.button, className, 'z-50')}
			type="button"
			onClick={onClick}
			data-testid="burger"
		>
			<IconWrapper icon={Icon} wrapperClassName="text-primary w-lg" />
		</Center>
	)
}

BurgerButton.propTypes = {
	onClick: func.isRequired,
	isOpen: bool.isRequired,
	className: string
}

export default BurgerButton
