import * as React from 'react'
import * as R from 'ramda'
import { useSelector } from 'react-redux'
import { Global } from '@emotion/core'
import { node } from 'prop-types'
import {useEffect, useState} from "react";
import GoogleFontLoader from "react-google-font";

function ThemeWrapper({ children }) {
	const { styles, client } = useSelector(state => state.user)
	const [fonts, setFonts] = useState({});

	useEffect(() => {
		if(client.scheme) {
			const schemeFonts = [];
			if(client.scheme.baseFont) {
				schemeFonts.push({
					font: client.scheme.baseFont,
					weights: [300, 400, 500, 700]
				})
			}
			if(client.scheme.headerFont) {
				schemeFonts.push({
					font: client.scheme.headerFont,
					weights: [300, 400, 500, 700]
				})
			}
			setFonts(schemeFonts);
		}
	}, [client?.scheme])

	return (
		<>
			{!R.isEmpty(fonts) &&
				<GoogleFontLoader
					fonts={fonts}
				/>
			}

			{styles && <Global styles={styles} />}
			{children}
		</>
	)
}

ThemeWrapper.propTypes = {
	children: node.isRequired
}

export default ThemeWrapper
