import * as React from 'react'
import ReactModal from 'react-modal'
import { useSelector, useDispatch } from 'react-redux'
import Button from '@/components/buttons/Button'
import classNames from 'classnames'
import Link from '@/utils/Link'
import Text from '@/utils/Text'
import IconWrapper from '@/utils/IconWrapper'
import CloseIcon from '@/icons/icon-close-xs.svg'
import CookiesNoticeAccordion from './CookiesNoticeAccordion'
import styles from './CookiesNotice.module.css'
import * as m from '@/utils/messages'

function CookiesNotice({ pathname }){

	const [seeCookieSettings, setSeeCookieSettings] = React.useState(false)

	const [necessaryExpanded, setNecessaryExpanded] = React.useState(false)
	const [nonNecessaryExpanded, setNonNecessaryExpanded] = React.useState(false)

	const dispatch = useDispatch()
	const { masquerade } = useSelector(state => state.user)
	const { cookiesAccepted, nonNecessaryCookiesEnabled } = useSelector(state => state.cookie)

	const exclude = pathname !== '/'

	return(
		<>
			{!seeCookieSettings && !cookiesAccepted && !masquerade?.userId && (
				<div className={classNames(styles.cookieBanner, 'z-40 rounded-10 bg-grey-20 p-md ml-sm mb-xl md:mb-md')}>
					<div className={classNames(styles.cookieBannerInner)}>
						<p>This website uses cookies to give you the best experience. You can find out more or change your settings
							<button
								type='button'
								className='text-primary'
								onClick={() => setSeeCookieSettings(true)}
							> &nbsp;here
							</button>
						</p>
						<Button
							className={classNames(styles.acceptCookiesButton, 'ml-xl')}
							onClick={() => {
								dispatch({
									type: 'cookie/ACCEPT_COOKIES'
								})
							}}
						>
							Accept cookies
						</Button>
					</div>
				</div>
			)}
			{seeCookieSettings && !cookiesAccepted && !masquerade?.userId && (
				<ReactModal
					isOpen
					closeTimeoutMS={300}
					className="outline-none"
					overlayClassName="perf-fixed z-100 inset-0 w-full flex items-center justify-center"
				>
					<div className="relative md:w-600 mx-auto overflow-hidden bg-white shadow rounded-20 md:rounded-30 lg:round p-lg">
						<div className="relative z-10">
							<Text as='h2' className='mb-lg md:ml-sm'>Cookie Settings</Text>
							<button
								type='button'
								onClick={() => setSeeCookieSettings(false)}
								className='absolute flex items-center top-sm right-sm'
							>
								{/* inset-y-0 right-0 flex items-center mr-smt */}
								<IconWrapper
									wrapperClassName="w-md text-primary"
									className="fill-current"
									icon={CloseIcon}
								/>
							</button>
							<Text as='p' className='mb-lg md:ml-sm'>This website uses cookies to ensure you get the best experience from our website. For full details view our <Link to="/cookie-policy/">cookie policy</Link>.</Text>
							<CookiesNoticeAccordion
								expanded={necessaryExpanded}
								setExpanded={setNecessaryExpanded}
								index={1}
								text={'Necessary'}
								necessary
							>
								<Text as='p' className='text-xs md:text-base mb-md'>
									{ m.NECESSARY_COOKIES }
								</Text>
							</CookiesNoticeAccordion>
							<CookiesNoticeAccordion
								className='mt-3xl'
								expanded={nonNecessaryExpanded}
								setExpanded={setNonNecessaryExpanded}
								index={2}
								text={'Non-Necessary'}
								necessary={false}
								nonNecessary={nonNecessaryCookiesEnabled}
							>
								<Text as='p' className='text-xs md:text-base mb-md'>
									{ m.NON_NECESSARY_COOKIES }
								</Text>
							</CookiesNoticeAccordion>
						</div>
					</div>
				</ReactModal>
			)}
		</>
	)
}

export default CookiesNotice
