import * as React from 'react'
import { MASQUERADE_BANNER_NOTICE } from '@/utils/messages'

const MasqueradeBanner = ( { name, schemeYear } ) =>
{
	return (
		<div data-testid="masquerade-banner" className="w-full bg-grey-20 fixed bottom-3xl z-10 opacity-85">
			<span className="flex justify-center items-center h-48 text-black">
				{ MASQUERADE_BANNER_NOTICE(name, schemeYear) }
			</span>
		</div>
	)
}

export default MasqueradeBanner
