import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { navigate } from 'gatsby'
import { getPersistor} from '@rematch/persist'
import useProgressBar from '@/hooks/useProgressBar'
import fetchApi from '@/utils/fetchApi'
import * as restAPI from '@/utils/endpoints'

function useLogout() {
	const dispatch = useDispatch()
	const { setLoading } = useProgressBar()

	// change to async function
	return useCallback(
		async e => {
			// prevent the default, or the page will be redirected before having a chance to logout
			if (e) {
				e.preventDefault()
			}

			setLoading(true)
			// wait for the response
			await fetchApi(restAPI.V2_LOGOUT)

			await fetchApi(restAPI.BEAM_LOGOUT, {
				method: "DELETE"
			})

			dispatch({
				type: 'user/LOGOUT'
			})

			/*
			* We have to pause the persistor here, otherwise when the navigation to the homepage happens, the
			* persist library will get confused and will not retrieve the state correctly due the reset that takes place.
			*/
			getPersistor().pause();

			dispatch({
				type: 'RESET_APP'
			})

			getPersistor().persist();

			navigate('/')
		},
		[dispatch, setLoading]
	)
}

export default useLogout
