import * as React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import * as R from "ramda";
import * as m from '@/utils/messages';

function useCCVOnly() {
	const dispatch = useDispatch()

	const { loggedIn, employmentStatus, benefitBrandName, supportEmail } = useSelector(
		state => state.user
	)

	const { products, ccvBalance, benefitsLoaded, ccvOnly } = useSelector(	state => state.benefits)
	const [ showCCVOnly, setShowCCVOnly ] = React.useState(false);

	React.useEffect(() => {
		if(ccvOnly) {
			setShowCCVOnly(ccvOnly)
		} else {
			setShowCCVOnly(false)
		}
	}, [ccvOnly])

	React.useEffect(() => {
		if(loggedIn && benefitsLoaded) {
			const ccv = R.compose(
				R.find(v => v.groupCode === 'ccv'),
				R.values
			)(products)

			if(employmentStatus == 'inactive' || employmentStatus == 'left') {
				if(ccv && ccvBalance.balance == 0 || !ccv) {
					dispatch({
						type: "user/LOGOUT",
						payload: m.NO_ACCESS_LOGOUT({benefitBrandName, supportEmail})
					})
				} else if(ccvBalance.balance > 0) {
					dispatch({
						type: "benefits/SET_CCV_ONLY",
						payload: true
					})
				}
			}
		}
	}, [loggedIn, benefitsLoaded, ccvBalance])

	return showCCVOnly
}

export default useCCVOnly
