import * as React from 'react'
import { string, bool } from 'prop-types'
import Link from '@/utils/Link'
import classNames from 'classnames'
import useHeadroom from '@/hooks/useHeadroom'
import MobileNavigation from '@/components/global/MobileNavigation'
import DesktopNavigation from '@/components/global/DesktopNavigation'
import DropDownMenu from '@/components/widgets/DropDownMenu'
import useMediaQuery from '@/hooks/useMediaQuery'
import IconText from '@/utils/IconText'
import Text from '@/utils/Text'
import VisuallyHidden from '@/utils/VisuallyHidden'
import Center from '@/utils/Center'
import Avatar from '@/components/user/Avatar'
import useLogout from '@/hooks/useLogout'
// import DesktopLogo from '@/icons/icon-desktop-logo.svg'
// import MobileLogo from '@/icons/icon-mobile-logo.svg'
import styles from './Header.module.css'

function Header({ loggedIn = false, name, clientLogo, benefitBrandName, brandHeaderLogo, brandHeaderLogoMobile, avatar }) {
	const isTablet = useMediaQuery('(min-width: 768px)')
	const logout = useLogout()
	const node = React.useRef()
	const { innerStyle, wrapper } = useHeadroom(node)
	const homeUrl = loggedIn ? '/app/home/' : '/'

	return (
		<div ref={node} style={{ ...wrapper, position: 'relative', zIndex: '90' }}>
			<header
				style={innerStyle}
				className={classNames(styles.header, 'shadow-header')}
			>
				<Center data-testid="logo" as={Link} to={homeUrl}>
					<div style={{ height: 32 }}>
						<img
							alt=""
							src={brandHeaderLogo}
							className="hidden h-full lg:block"
							loading="lazy"
						/>
						<img
							alt=""
							src={brandHeaderLogoMobile}
							className="h-full lg:hidden"
							loading="lazy"
						/>
					</div>
					{/* <DesktopLogo width={182} className="hidden text-primary lg:block"/>
					<MobileLogo width={26} className="text-primary lg:hidden"/> */}
					<VisuallyHidden>{`${benefitBrandName}`}</VisuallyHidden>
				</Center>
				{loggedIn && (
					<>
						<DesktopNavigation hidden={!isTablet} />
						{isTablet && (
							<div
								data-testid="user-menu"
								className="flex items-center ml-auto"
							>
								<DropDownMenu
									dropdownClassName="right-0 mt-md"
									links={[
										{
											slug: '/app/home/my-profile/',
											title: 'My profile settings',
											id: 1,
											'data-testid': 'profile-link'
										},
										{
											slug: '/',
											title: 'Logout',
											id: 2,
											onClick: logout,
											'data-testid': 'logout-link'
										}
									]}
								>
									<IconText className="text-primary">
										<Text
											inline
											className="hidden text-base lg:inline-block font-h-light"
										>
											Hi, {name}
										</Text>
										{/* {RELEASE_MVP && <Avatar className="lg:ml-md" />} */}
										<Avatar image={avatar} className="lg:ml-md" />
									</IconText>
								</DropDownMenu>
							</div>
						)}
					</>
				)}
				{clientLogo && (
					<div
						className={classNames(
							'flex md:justify-end items-center max-w-150 md:ml-auto',
							{ 'md:ml-auto': !loggedIn, 'md:ml-xl': loggedIn }
						)}
					>
						<img
							src={clientLogo}
							alt="brand-logo"
							style={{ maxWidth: 150, maxHeight: 38, padding: 0 }}
							className="flex-shrink-0 object-contain w-full h-full mx-auto py-sm"
						/>
					</div>
				)}
				{!isTablet && loggedIn && <MobileNavigation />}
			</header>
		</div>
	)
}

Header.propTypes = {
	loggedIn: bool,
	name: string,
	clientLogo: string,
	benefitBrandName: string,
	brandHeaderLogo: string,
	brandHeaderLogoMobile: string,
	avatar: string
}

export default Header
