import { useEffect } from 'react'
import raf from 'raf-throttle'

const setStyle = value => {
	document.documentElement.style.setProperty('--vh', `${value * 0.01}px`)
}

const handle = raf(() => {
	setStyle(window.innerHeight)
})

function useRealVh() {
	useEffect(() => {
		setStyle(window.innerHeight)
		window.addEventListener('resize', handle)

		return () => {
			window.removeEventListener('resize', handle)
		}
	}, [])
}

export default useRealVh
