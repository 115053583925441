import * as React from 'react'
import { node, string } from 'prop-types'
import classNames from 'classnames'
import { useSelector } from 'react-redux'
import Header from '@/components/global/Header/Header'
import CookiesNotice from '@/components/global/CookiesNotice'
import Footer from '@/components/global/Footer/Footer'
import TimeoutPopup from '@/components/screens/TimeoutPopup'
import useHideFocus from '@/hooks/useHideFocus'
import useRealVh from '@/hooks/useRealVh'
import useIdleUser from '@/hooks/useIdleUser'
import fetchApi from '@/utils/fetchApi'
import withClientTheme from '@/utils/withClientTheme'
import '@/style/main.css'
import MasqueradeBanner from "../components/global/MasqueradeBanner/MasqueradeBanner";

const timeout = 1000 * 60 * 14 // 14 minutes

function Layout({ children, className, appClassName }) {

	const {
		loggedIn,
		name,
		clientLogo,
		benefitBrandName,
		brandHeaderLogo,
		brandHeaderLogoMobile,
		siteTermsUpdated,
		masquerade,
		client
	} = useSelector(({ user }) => user)

	const [isIdle, setIdle] = useIdleUser(loggedIn ? timeout : false, {
		loggedIn
	})
	const userReady = !siteTermsUpdated && loggedIn

	const schemeYear = client?.scheme?.schemeYear

	const avatar = client?.scheme?.avatarSet?.avatarsetPlainFileURL

	useHideFocus()
	useRealVh()

	return (
		<div
			className={classNames(
				'flex flex-col min-h-screen transition-filter transition-default w-full',
				appClassName
			)}
		>
			{isIdle ? (
				<TimeoutPopup
					onClick={async () => {
						await fetchApi('ping')
						setIdle(false)
					}}
				/>
			) : (
				<>
					<Header loggedIn={userReady} name={name} clientLogo={clientLogo} benefitBrandName={benefitBrandName} brandHeaderLogo={brandHeaderLogo} brandHeaderLogoMobile={brandHeaderLogoMobile} avatar={avatar} />
					<div
						role="main"
						className={classNames('bg-white relative flex-grow', className)}
					>
						<div className="flex flex-col flex-grow w-full mx-auto max-w-page px-md md:px-lg lg:px-2xl">
							{children}
						</div>
						<CookiesNotice pathname={location.pathname}/>
					</div>
					{masquerade?.userId && (
						<MasqueradeBanner name={name} schemeYear={schemeYear} />
					)}
					<Footer contactUsEnabled={userReady && client.contactUsEnabled} />
				</>
			)}
		</div>
	)
}

Layout.propTypes = {
	className: string,
	appClassName: string,
	children: node.isRequired
}

export default withClientTheme(Layout)

// export default Layout
