import * as React from 'react'
import { number, node, string, bool } from 'prop-types'
import IconWrapper from './IconWrapper'
import DynamicRemoteIcon from './DynamicRemoteIcon'
function LazyLoadIcon({
	icon,
	fallback = null,
	className,
	wrapperClassName,
	dir = 'benefits',
	remote = false,
	alt = '',
	width = '',
	height = ''
}) {

	const [module, setModule] = React.useState(null)

	const remoteIconUrl = remote && (icon.substring(0, 4) === "http" ? icon : remote )
	const remoteIsSVG = remote && icon.substr(-4) === '.svg'

	React.useLayoutEffect(() => {
		let isLoadingFallback = false;

		async function loadIcon() {
			import(`@/icons/${dir}/${icon}.svg`)
				.then(resp => {
					const { default: mod } = resp

					setModule(
						<IconWrapper
							className={className}
							wrapperClassName={wrapperClassName}
							icon={mod}
						/>
					)
				})
				.catch(() => {
					if (!isLoadingFallback) {
						isLoadingFallback = true
						icon = fallback
						loadIcon();
					}
				})
		}
		if (!module && !remote) {
			loadIcon()
		}
	}, [className, dir, icon, module, wrapperClassName, remoteIsSVG])

	if (remoteIsSVG) {
		return (
			<DynamicRemoteIcon
				className={className}
				wrapperClassName={wrapperClassName}
				icon={remoteIconUrl}
			/>
		)
	}
	else if (remote && !remoteIsSVG)
	{
		return(
		<img
			src={icon}
			width={width}
			height={height}
			alt={alt}
			className={className}
		/>
		)
	}

	return module
}

LazyLoadIcon.propTypes = {
	icon: string.isRequired,
	width: string,
	height: string,
	fallback: node,
	className: string,
	wrapperClassName: string,
	dir: string,
	remote: bool,
	alt: string
}

export default React.memo(LazyLoadIcon)
