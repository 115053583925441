import * as React from 'react'
import { bool, func, number, oneOfType, node } from 'prop-types'
import { motion, AnimatePresence } from 'framer-motion'
import classNames from 'classnames'
import IconWrapper from '@/utils/IconWrapper'
import ChevronRight from '@/icons/chevron-right.svg'
import ChevronDown from '@/icons/chevron-down.svg'
import "react-toggle/style.css"
import Toggle from 'react-toggle'
import {useDispatch} from "react-redux";

function CookiesNoticeAccordion({ children, expanded = false, setExpanded, index, text, necessary, nonNecessary}) {
	const isOpen = index === expanded
	const ArrowIcon = isOpen ? ChevronDown : ChevronRight

	const dispatch = useDispatch()

	return(
		<>
			<div className='flex items-center justify-between w-full'>
				<button
					type="button"
					onClick={e => {
						e.preventDefault()
						setExpanded(isOpen ? false : index)
					}}
					className="flex w-full items-center outline-none focus:outline-none bg-grey-20 rounded-10 p-sm mb-md"
					data-testid={`accordion-${index}`}
				>
					<IconWrapper
						wrapperClassName="w-md mr-lg text-primary"
						className="fill-current"
						icon={ArrowIcon}
					/>
					<span className="w-1/2 md:w-3/4 text-left text-mob-base text-primary md:text-md">{text}</span>
					{necessary && (
						<p className='flex w-1/4 mr-sm'>Always Enabled</p>
					)}
					{!necessary && (
						<div className='flex w-1/2 md:w-1/4  items-center justify-center mr-sm'>
							<Toggle
								id='non-necessary-cookies'
								checked={nonNecessary}
								onChange={e => {
									e.stopPropagation()
									dispatch({
										type: 'cookie/SET_NON_NECESSARY_COOKIES',
										payload: { nonNecessaryCookiesEnabled:  !nonNecessary}
									})
								}}
							/>
							<label htmlFor='non-necessary-cookies' className='ml-sm'>Enabled</label>
						</div>
					)}

				</button>
			</div>
			<AnimatePresence initial={false}>
				{isOpen && (
					<motion.div
						key={index}
						initial="collapsed"
						animate="open"
						exit="collapsed"
						variants={{
							open: { opacity: 1, height: 'auto' },
							collapsed: { opacity: 0, height: 0 }
						}}
						transition={{ duration: 0.6, ease: [0.04, 0.62, 0.23, 0.98] }}
						className="relative overflow-hidden"
					>
						{children}
					</motion.div>
				)}
			</AnimatePresence>
		</>
	)
}

CookiesNoticeAccordion.propTypes = {
	expanded: oneOfType([bool, number]).isRequired,
	setExpanded: func.isRequired,
	index: number.isRequired,
	text: node.isRequired,
	children: node.isRequired,
	necessary: bool.isRequired,
	nonNecessary: bool
}

export default CookiesNoticeAccordion
