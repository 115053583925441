import * as React from 'react'
import {
	string,
	node,
	element,
	object,
	any,
	oneOfType,
	oneOf
} from 'prop-types'
import classNames from 'classnames'
import Node from './Node'

function IconText({
	as = 'div',
	align = 'center',
	children,
	className,
	...rest
}) {
	return (
		<Node
			as={as}
			className={classNames('flex', className, {
				'items-center': align === 'center',
				'items-baseline': align === 'baseline',
				'items-start': align === 'start',
				'items-end': align === 'end'
			})}
			{...rest}
		>
			{children}
		</Node>
	)
}

IconText.propTypes = {
	children: node,
	className: string,
	align: oneOf(['center', 'baseline', 'start', 'end']),
	as: oneOfType([string, object, node, element, any]) //
}

export default React.memo(IconText)
