import * as React from 'react'
import { string, bool, func, node, oneOfType, objectOf } from 'prop-types'
import * as R from 'ramda'
import classNames from 'classnames'
import TextInput from '@/components/formFields/TextInput'
import Link from '@/utils/Link'
import Text from '@/utils/Text'
import IconText from '@/utils/IconText'
import IconWrapper from '@/utils/IconWrapper'
import Circle from '@/utils/Circle'
import ShowIcon from '@/icons/icon-show.svg'
import HideIcon from '@/icons/icon-hide.svg'
import TickIcon from '@/icons/icon-avail-tick.svg'
import * as m from '@/utils/messages'

function InputRule({ children, valid }) {
	return (
		<IconText>
			<Circle
				className={classNames('w-md h-md mr-md', {
					'bg-primary-20': !valid,
					'bg-s-success': valid
				})}
			>
				{valid && (
					<IconWrapper icon={TickIcon} wrapperClassName="text-white w-xs" />
				)}
			</Circle>
			<Text className="flex-grow text-sm text-grey-80">{children}</Text>
		</IconText>
	)
}

InputRule.propTypes = {
	children: node.isRequired,
	valid: bool
}

function ShowInput({ onClick, className, text = 'Show', type }) {
	const Icon = type === 'password' ? ShowIcon : HideIcon
	return (
		<IconText
			as="button"
			type="button"
			className={classNames('text-primary relative justify-between', className)}
			onClick={onClick}
			style={{ width: `4.21875rem` }}
			data-testid="show-input"
		>
			<IconWrapper icon={Icon} wrapperClassName="w-lg text-primary" />
			{text}
		</IconText>
	)
}

ShowInput.propTypes = {
	onClick: func.isRequired,
	className: string,
	text: string,
	type: string
}

const Password = React.forwardRef(
	(
		{
			title,
			className,
			error,
			showForgttenLink = false,
			showVisibilityToggle = true,
			showInputClassName = 'md:left-100 md:b-md',
			rules,
			...rest
		},
		ref
	) => {
		const [type, setType] = React.useState('password')

		return (
			<>
				<label
					htmlFor="password"
					className={classNames(
						'mb-md block relative flex flex-wrap items-baseline',
						className
					)}
				>
					<Text
						as="span"
						inline
						className="order-1 text-mob-lg font-h-light mb-lg"
					>
						{title}
					</Text>

					<TextInput
						type={type}
						error={error}
						wrapperClassName="order-3 w-full"
						ref={ref}
						{...rest}
					/>
					{(showVisibilityToggle || showForgttenLink) && (
						<div className="flex items-center justify-between order-2 ml-auto mb-lg">
							{showVisibilityToggle && (
								<ShowInput
									className={classNames(
										'text-sm text-primary md:ml-lg',
										showInputClassName
									)}
									text={type === 'password' ? 'Show' : 'Hide'}
									onClick={() => {
										setType(type === 'password' ? 'text' : 'password')
									}}
									type={type}
								/>
							)}
							{showForgttenLink && (
								<Text
									as={Link}
									to="/forgotten-password/"
									inline
									className="text-sm text-primary ml-xl"
								>
									Forgotten?
								</Text>
							)}
						</div>
					)}
				</label>
				{rules && (
					<>
						<Text as={'p'} className="mb-lg">
							{m.DATA_SECURITY_CRITERIA}
						</Text>
						<ul>
							{R.toPairs(rules).map(([key, valid]) => (
								<li key={key} className="mb-md stack">
									<InputRule valid={valid}>{key}</InputRule>
								</li>
							))}
						</ul>
					</>
				)}
			</>
		)
	}
)

Password.propTypes = {
	title: string.isRequired,
	showForgttenLink: bool,
	showVisibilityToggle: bool,
	className: string,
	showInputClassName: string,
	error: oneOfType([string, node, bool]),
	rules: objectOf(bool)
}

export default Password
