import * as React from 'react'
import { node, oneOf, string, oneOfType } from 'prop-types'
import classNames from 'classnames'

function Notification({ children, level, className, size = 'md', ...rest }) {
	return (
		<div
			{...rest}
			className={classNames(
				`rounded-10`,
				{
					'p-md text-base': size === 'md',
					'p-sm text-sm': size === 'sm'
				},
				{
					[`text-white`]: level === 'error',
					[`text-s-${level}`]: level !== 'error',
					'text-center': !className,
					'bg-s-error-bg-50': level === 'error',
					'bg-s-warning-bg-50': level === 'warning',
					'bg-s-success-bg-50': level === 'success'
				},
				className
			)}
		>
			<div className="font-h-light">{children}</div>
		</div>
	)
}

Notification.propTypes = {
	className: string,
	children: oneOfType([node, string]),
	level: oneOf(['warning', 'error', 'success']).isRequired,
	size: oneOf(['sm', 'md'])
}

export default Notification
