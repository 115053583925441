import * as React from 'react'

function useFocusOnFirstInput(ref) {
	React.useEffect(() => {
		const node = ref.current

		if (node) {
			// get the first input element
			const input = node.querySelector('input')
			if (input) input.focus()
		}
	}, [ref])
}

export default useFocusOnFirstInput
