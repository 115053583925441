import * as React from 'react'
import { func } from 'prop-types'
import Link from '@/utils/Link'
import Text from '@/utils/Text'
import Center from '@/utils/Center'
import Button from '@/components/buttons/Button'
import useLogout from '@/hooks/useLogout'
import useTimer from '@/hooks/useTimer'
import { useSelector } from 'react-redux'
import * as m from '@/utils/messages'

function TimeoutPopup({ onClick }) {
	const logout = useLogout()
	const { loggedIn } = useSelector(state => state.user)
	const time = useTimer(15)

	React.useEffect(() => {
		if (time === 0 && loggedIn) {
			logout()
		}
	}, [logout, time, loggedIn])

	return (
		<Center className="h-screen mx-auto max-w-login">
			<div>
				<Text as="h2" className="text-2xl mb-lg font-h-light">
					Stay logged in?
				</Text>
				<Text className="mb-xl">{m.LOG_OUT_ALERT}</Text>
				<Text className="text-2xl mb-xl font-h-light">{time} seconds</Text>
				<div className="flex justify-start">
					<Button onClick={onClick} className="flex-grow-3 mr-md">
						Stay logged in
					</Button>
					<Button
						theme="secondary"
						as={Link}
						to="/"
						onClick={logout}
						className="text-center flex-grow-2"
					>
						Logout
					</Button>
				</div>
			</div>
		</Center>
	)
}

TimeoutPopup.propTypes = {
	onClick: func.isRequired
}

export default React.memo(TimeoutPopup)
