import { useState } from 'react'
import { differenceInMilliseconds } from 'date-fns'
import useInterval from './useInterval'

function useIdleUser(timeout, { loggedIn }) {
	const [isIdle, setIdle] = useState(false)

	useInterval(() => {
		if (loggedIn) {
			const stamp = sessionStorage.getItem(`__SESSION__TIME__`)
			if (stamp) {
				const sessionTime = JSON.parse(stamp)
				const diff = differenceInMilliseconds(Date.now(), sessionTime)
				setIdle(diff >= timeout)
			}
		}
	}, 1000)

	return [isIdle, setIdle]
}

export default useIdleUser
