import * as React from 'react'
import Login from '@/templates/Login'

function Index({ssoOnly}) {


	return <Login ssoOnly={ssoOnly} />
}

Index.defaultProps = {
	ssoOnly: (SSO_ONLY==='1')
}

export default Index
