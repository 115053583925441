import * as React from 'react'
import { string, arrayOf, shape, oneOfType, number, node } from 'prop-types'
import Link from '@/utils/Link'
import { motion, AnimatePresence } from 'framer-motion'
import classNames from 'classnames'
import ArrowDown from '@/icons/icon-arrow-down.svg'
import { getPrevNext } from '@/utils'
import IconWrapper from '@/utils/IconWrapper'
import styles from './DropDownMenu.module.css'

function DropDownMenu({ links, children, dropdownClassName }) {
	const [isVisible, setVisible] = React.useState(0)
	const [index, setIndex] = React.useState(0)
	const menuButton = React.useRef()
	const dropdown = React.useRef()
	const $links = React.useRef(
		Array.from({ length: links.length }, () => React.createRef())
	)

	React.useEffect(() => {
		if (isVisible) {
			if ($links.current[index].current) {
				$links.current[index].current.focus()
			}
		}
	}, [index, isVisible])

	function onBlur() {
		setTimeout(() => {
			if (
				dropdown.current &&
				!dropdown.current.contains(document.activeElement) &&
				document.activeElement !== menuButton.current
			) {
				setVisible(false)
			}
		})
	}

	return (
		<div className="relative flex items-center" aria-label="User Menu">
			<button
				data-testid="dropdown-button"
				id="usermenu-button"
				className="flex items-center relatve group"
				type="button"
				onClick={() => {
					setVisible(!isVisible)
				}}
				aria-haspopup="true"
				aria-expanded={isVisible ? 'true' : 'false'}
				ref={menuButton}
			>
				{children}
				<IconWrapper
					icon={ArrowDown}
					wrapperClassName={classNames('w-md ml-md group-hover:text-accent', {
						'text-accent': isVisible,
						'text-primary': !isVisible
					})}
				/>
			</button>
			<AnimatePresence>
				{isVisible && (
					<motion.div
						initial={{ opacity: 0 }}
						animate={{ opacity: 1 }}
						exit={{ opacity: 0 }}
						className={classNames(styles.dropdown, dropdownClassName, {
							'pointer-events-none': !isVisible
						})}
						aria-labelledby="usermenu-button"
						role="menu"
						data-testid="dropdown-menu"
						key="dropdown"
						tabIndex="-1"
						ref={dropdown}
						onBlur={onBlur}
						onKeyDown={e => {
							const { key } = e
							if (key === 'Escape') {
								setVisible(false)
								menuButton.current.focus()
								return
							}
							if (key === 'ArrowUp' || key === 'ArrowDown') {
								e.preventDefault()
								if (key === 'ArrowDown') {
									const newIndex = getPrevNext('next', links.length, index)
									$links.current[newIndex].current.focus()
								}
								if (key === 'ArrowUp') {
									const newIndex = getPrevNext('prev', links.length, index)
									$links.current[newIndex].current.focus()
								}
							}
						}}
					>
						{links.map(({ slug, title, ...rest }, i) => (
							<Link
								key={title}
								data-testid="dropdown-item"
								role="menuitem"
								to={slug}
								className={classNames(
									'text-primary block font-h-light text-center py-md hover:text-accent hover:bg-accent-5 focus:bg-accent-5',
									styles.button
								)}
								ref={$links.current[i]}
								onFocus={() => {
									setIndex(i)
									if (!isVisible) {
										setVisible(true)
									}
								}}
								{...rest}
							>
								{title}
							</Link>
						))}
					</motion.div>
				)}
			</AnimatePresence>
		</div>
	)
}

DropDownMenu.propTypes = {
	links: arrayOf(
		shape({
			slug: string.isRequired,
			title: string.isRequired,
			id: oneOfType([number, string]).isRequired
		})
	).isRequired,
	children: node,
	dropdownClassName: string
}

export default DropDownMenu
