import * as React from 'react'

import classNames from 'classnames'
import { string, node } from 'prop-types'
import Center from './Center'

function Circle({ as = 'div', children, className, ...rest }) {
	return (
		<Center
			as={as}
			className={classNames('rounded-full flex-shrink-0', className, {})}
			{...rest}
		>
			{children}
		</Center>
	)
}

Circle.propTypes = {
	children: node,
	className: string,
	as: string
}

export default Circle
