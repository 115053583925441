import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Loading from '@/components/screens/Loading'
import ThemeWrapper from '@/container/ThemeWrapper'
import Spinner from './Spinner'

function withClientTheme(Component) {
	const ClientThemeWrapper = props => {
		const { hasClientTheme } = useSelector(state => state.user)
		const [isClient, setClient] = React.useState(false)
		const dispatch = useDispatch()

		React.useEffect(() => {
			setClient(true)
		}, [])

		React.useEffect(() => {
			const domain = window.location.hostname.split('.')[0]
			dispatch({ type: 'user/REQUEST_THEME_FROM_DOMAIN', payload: domain })
		}, [dispatch])

		function componentRender() {
			if (isClient) {
				if (hasClientTheme) {
					return (
						<ThemeWrapper>
							<Component {...props} />
						</ThemeWrapper>
					)
				}
				return (
					<div className="flex flex-col items-center justify-center flex-grow w-full">
						<Spinner style={{ width: 40, height: 40 }} className="mb-xl" />
					</div>
				)
			}

			return null
		}

		return componentRender()
	}

	return ClientThemeWrapper
}

export default withClientTheme
