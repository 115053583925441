import { useEffect, useState } from 'react'

function useTimer(start) {
	const [time, setTime] = useState(start)

	useEffect(() => {
		let handle
		let frame
		const countdown = () => {
			handle = setTimeout(() => {
				if (time > 0) {
					setTime(time - 1)
				}
				frame = requestAnimationFrame(countdown)
			}, 1000)
		}

		countdown()
		return () => {
			clearTimeout(handle)
			cancelAnimationFrame(frame)
		}
	}, [time])

	return time
}

export default useTimer
