import * as React from 'react'
import useProgressBar from '@/hooks/useProgressBar'

function useFormState(isSubmitting) {
	const { setLoading } = useProgressBar()
	React.useEffect(() => {
		setLoading(isSubmitting)
	}, [isSubmitting, setLoading])
}

export default useFormState
